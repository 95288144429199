.group-menu {
  position: relative;
  display: inline-block;
  font-size: $font-size-16;

  &__button {
    font-size: 12px;
    cursor: pointer;
    margin-top: 4px;
    margin-left: 6px;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 0.21429em $andes-blue-500;
    }

    span {
      position: relative;
      top: -3px;
      display: inline-block;
      width: 7px;
      height: 7px;
      border-bottom: 2px solid $andes-gray-100;
      border-right: 2px solid $andes-gray-100;
      transform: rotate(45deg);
      margin-left: 8px;
    }

    .icon-wrapper {
      width: unset;
    }
  }

  &__popup {
    text-align: center;
    position: absolute;
    top: 42px;
    right: -8px;
    z-index: 9;
    width: 260px;
    background: $andes-white;
    border-radius: 6px;
    box-shadow: 0 3px 6px 0 $andes-gray-550, 0 1px 1px 0 $andes-gray-100;
    animation: fade-in 70ms ease-in;

    &::before {
      position: absolute;
      top: -6px;
      border-top: solid 1px $andes-gray-100;
      border-left: solid 1px $andes-gray-100;
      right: 12px;
      width: 12px;
      height: 12px;
      background: $andes-white;
      display: block;
      transform: rotate(45deg);
      content: '';
    }

    &-option {
      padding: 16px;

      &:not(:first-child) {
        border-top: 0.5px solid $andes-gray-550;
      }
    }
  }
}
